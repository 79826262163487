import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ApiProvider from '../Providers/ApiProvider'
import Footer from './Footer'
import Header from './Header'

function Layout() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <ApiProvider>
            <Header />
            <Outlet />
            <Footer />
        </ApiProvider>
    )
}

export default Layout