import { Helmet } from 'react-helmet'
import AboutNotes from '../components/AboutNotes'
import AcademicianBanner from '../components/AcademicianBanner'
import Awards from '../components/Awards'
import ScheduleMeet from '../components/ScheduleMeet'

const Academician = () => {
    const data = [
        { text: 'Journeys to the Board Room | Vijay Patil School of Management ', year: 2024 },
        { text: 'Top 30 Talent Leaders | presented by Forbe’s powered by indeed ', year: 2023 },
        { text: 'Asia’s 100 power leaders in Human Resources | White page International ', year: 2022 },
        { text: 'Global Achievers Award | Indian Achievers form ', year: 2022 },
        { text: 'Indian Achievers Award | Indian Achievers form ', year: 2021 },
        { text: 'The voice of the Employee | Josh Bersin Academy ', year: 2021 },
        { text: 'Juggling: Learning the Basics and Beyond | Udemy ', year: 2021 },
        { text: 'RPA Starter Diploma of Completion | UiPath ', year: 2021 },
        { text: 'Coaching at Scale | Josh Bersin Academy ', year: 2021 },
        { text: 'Hypnosis-Hynotic Induction Mastery | Udemy ', year: 2020 },
        { text: 'Complete Design Thinking Masterclass | Udemy ', year: 2020 },
        { text: 'General Management Conclave | INACON ', year: 2018 },
    ]

    return (
        <div>
            <Helmet>
                <title>Cutting-Edge Research & Impactful Education </title>
                <meta name="description" content="Discover the cutting-edge research, impactful education, and industry collaborations of Satish Rajarathnam. Learn more about his innovative contributions." />
            </Helmet>
            <AcademicianBanner />
            <AboutNotes title='ACADEMICIAN' content={`Satish Rajarathnam is an accomplished academician who excels in research, education, and industry collaboration. He has expertise in human resource management, organizational behavior, and strategic leadership. Satish's groundbreaking research is published in prestigious journals and presented at esteemed conferences worldwide. He delves into real-world applications and has facilitated meaningful collaborations between academia and the corporate world. As an educator and mentor, he empowers students and scholars to push the boundaries of knowledge and embrace interdisciplinary perspectives. In essence, Satish Rajarathnam's academic journey is characterized by a relentless pursuit of excellence, inspiring the next generation of leaders and change-makers.`} />
            <ScheduleMeet />
            <Awards data={data} />
        </div>
    )
}

export default Academician