import { Container, Grid, GridCol, Image, Text, Title } from "@mantine/core";
import aboutUs from '../../assets/images/about-us.png';
function AboutNotes({ title, content }) {
    return (
        <Container py={'xl'}>
            <Grid py={'xl'}>
                <GridCol span={{ lg: 4, base: 12 }} data-aos="fade-down">
                    <Image src={aboutUs} className="about-us" alt="about-us" />
                </GridCol>
                <GridCol span={{ lg: 8, base: 12 }} ta={{ lg: 'right', base: 'center' }} data-aos="fade-up">
                    <Title mb="md">BEING {title}</Title>
                    <Text ta='justify'>{content}</Text>
                    <br />
                    <br />
                    <Text ><b>Satish Rajaratham</b></Text>
                    <Text>{title}</Text>
                </GridCol>
            </Grid>
        </Container>
    )
}

export default AboutNotes