import { Button, Container, Flex, Image, Text, Title } from '@mantine/core';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';
import author02 from '../../assets/images/author-02.png';
import Effects from '../Effects';
import Features from '../Features';
import TextEffect from '../TextEffect';
import classes from './Banners.module.css';

export default function MentalistBanner() {
    return (
        <>
            <Effects />
            <div className="banner-box">
                <div className="banner-content animate__animated animate__fadeInDown">
                    <Container>
                        <Title className={classes.title}>
                            Illuminate Your Path: Empowering  <span className={classes.titleSpan}> <br /> Keynote Presentations</span>
                        </Title>
                        <Text className={classes.description} size={{ lg: 'xl', base: 'sm' }} mt={{ lg: "xl", base: 'sm' }} >Unlock Potential: Inspiring Keynotes by Satish Rajarathnam offers Motivating, Insightful, & Transformational Talks for Personal & Professional Growth.</Text>
                        <Flex gap='md' mt='md'>
                            <Button variant='filled' color='primary' component={HashLink} to='#contact'>Say Hello</Button>
                            <Button variant='outline' color='primary' component={HashLink} to='/#gallery'>Gallery</Button>
                        </Flex>
                        <TextEffect />

                    </Container>
                </div>
                <div className="banner-image-box">
                    <Image alt='Satish Rajarathnam' src={author02} className='banner-image animate__animated animate__fadeInUp' />
                    <div className="social-media-icons">
                        <a target='_blank' href="https://www.instagram.com/satishmentalist/" className='icon'><FaInstagram /></a>
                        <a target='_blank' href="https://www.facebook.com/satish.magician" className='icon'><FaFacebook /></a>
                        <a target='_blank' href="https://www.youtube.com/@satishrajarathnam8446/videos" className='icon'><FaYoutube /></a>
                        <a className='linked-icon' ><a target='_blank' href="https://www.linkedin.com/in/satishrajarathnam/?originalSubdomain=in" className='icon'><FaLinkedin /></a><b>LinkedIn</b></a>
                    </div>
                </div>
            </div>
            <Features />
        </>
    );
}