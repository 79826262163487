import { Box, Container, Flex, SimpleGrid, Title } from '@mantine/core';
import React from 'react';
import classes from './Features.module.css';

const Features = () => {
    return (
        <Box
            bg={'primaryDark'}
            className='animate__animated  animate__fadeInDown'
        >
            <Container size="md">
                <SimpleGrid
                    cols={{ sm: 3, md: 5 }}
                    spacing={{ base: 'xl', md: 100 }}
                    justify="center"
                    ta={'center'}
                    align="center"
                    mih={150}
                    py={25}
                >
                    <Flex align={"center"} direction={'column'} justify={'center'}>
                        <Title component={'div'} className={classes.featureTitle}>1420+</Title>
                        <div className={classes.featureContent}>Shows</div>
                    </Flex>
                    <Flex align={"center"} direction={'column'} justify={'center'}>
                        <Title component={'div'} className={classes.featureTitle}>260+</Title>
                        <div className={classes.featureContent}>Sessions</div>
                    </Flex>
                    <Flex align={"center"} direction={'column'} justify={'center'}>
                        <Title component={'div'} className={classes.featureTitle}>4+</Title>
                        <div className={classes.featureContent}>TEDx talks</div>
                    </Flex>
                    <Flex align={"center"} direction={'column'} justify={'center'} style={{ whiteSpace: 'nowrap' }}>
                        <Title component={'div'} className={classes.featureTitle}>9+</Title>
                        <div className={classes.featureContent}>Patents published</div>
                    </Flex>
                    <Flex align={"center"} direction={'column'} justify={'center'} style={{ whiteSpace: 'nowrap' }}>
                        <Title component={'div'} className={classes.featureTitle}>4+</Title>
                        <div className={classes.featureContent}>Books Authored</div>
                    </Flex>
                </SimpleGrid>
            </Container>
        </Box>
    )
}

export default Features