import { Box, Container, Grid, GridCol, Text } from '@mantine/core';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import Form from '../../components/Form';
import style from './Footer.module.css';
function Footer() {
    return (
        <footer className={style.footer} id='contact'>
            <Container size={'xl'}>
                <Grid gutter="md" justify='center'>
                    <Grid.Col span={{ base: 12, lg: 4, md: 4 }} ta={{ md: 'left', base: 'center' }}>
                        <Fade direction='up'>
                            <p className={style.title}><b>Satish</b> Rajaratham</p>
                            <p className={style.description}>
                                Journey through the diverse expertise of Satish Rajarathnam, a seasoned Business & HR leader with 26+ years of global experience across industries. Renowned for strategizing and transforming businesses through Human Capital Strategy, he fosters agility and digital capability. As a certified HR Technology evangelist, he leads transformative HR technology programs. Beyond corporate success, Satish captivates as a Mentalist and Magician, wowing audiences worldwide. A TEDx luminary, he inspires with insightful keynotes blending HR, academia, and arts. Satish pioneers innovation in academia, fostering collaboration between research, education, and industry. Explore his journey from esteemed institutions like ISB and NUS to philanthropic endeavors with Udavum Karangal.
                            </p>
                        </Fade>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4, md: 3 }} ta={{ md: 'center', base: 'center' }}>
                        <Fade direction='up'>
                            <ul className={style.links}>
                                <li className={style.linkLi}><b>Quick Links</b></li>
                                <li className={style.linkLi}><Link to='/'>Home</Link></li>
                                <li className={style.linkLi}><Link to='/keynote-speaker'>Keynote speaker</Link></li>
                                <li className={style.linkLi}><Link to='/mentalist'>Mentalist</Link></li>
                                <li className={style.linkLi}><Link to='/academician'>Academician</Link></li>
                                <li className={style.linkLi}><Link to='/contact'>Contact us</Link></li>
                            </ul>
                        </Fade>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4, md: 4 }} ta={{ md: 'right', base: 'center' }}>
                        <Fade direction='up'>
                            <b>Contact Us</b>
                            <Form />
                        </Fade>
                    </Grid.Col>
                </Grid>
                <Fade direction='up'>
                    <Box ta={{ md: 'left', base: 'center' }} >
                        <Text>Support: Consultation [or] enquires</Text>
                        <p style={{ marginTop: 0 }}>Mail: <a href="mailto:iam@satishrajarathnam.com" className={style.link}>iam@satishrajarathnam.com</a></p>
                    </Box>
                </Fade>
            </Container>
            <div className={style.bgPrimary}>
                <Container size={'xl'} ta={{ md: 'left', base: 'center' }}>
                    <Grid gutter="md" justify='space-between'>
                        <GridCol ta={{ lg: 'left', base: 'center' }} span={{ base: 12, md: 6 }}>Copyright @2024 <a href="https://ejnarstudios.com/" target='_blank'><Text display={'inline-block'} c={'white'}>ejnarstudios</Text></a> | All rights reserved by satishrajarathnam</GridCol>
                        <GridCol ta={{ lg: 'right', base: 'center' }} span={{ base: 12, md: 6 }}>Powered by SacredTrinityDigiagency</GridCol>
                    </Grid>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;