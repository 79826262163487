import { Helmet } from "react-helmet";
import AboutNotes from '../components/AboutNotes';
import Awards from '../components/Awards';
import KeyBanner from '../components/KeyBanner';
import ScheduleMeet from '../components/ScheduleMeet';

const KeyNoteSpeaker = () => {
    const data = [
        { text: `Influential HR Leaders | HRAI - HR association of India `, year: "2023" },
        { text: `Google Analytics for Beginners | Google Analytics Academy `, year: "2023" },
        { text: `Top 20 workplace Reinvention champions | L&D vision & Innovation summit `, year: "2022" },
        { text: `Leader of the year | HR Tech Summit `, year: "2022" },
        { text: `DevOps for Beginners: Docker, Kubernetes and Azure DevOps | Udemy `, year: "2021" },
        { text: `Learn Database Design with MySQL: Introduction | UpGrad `, year: "2021" },
        { text: `The Business Intelligence Analyst course| Udemy `, year: "2021" },
        { text: `Microsoft Azure AZ900-2020 | Udemy `, year: "2020" },
        { text: `The complete Python Course | Udemy `, year: "2020 " },
        { text: `Coaching Certification [Coaching skills Mastery] | Udemy `, year: "2020" },
        { text: `NLP Coaching Certification [Coaching Skills Mastery] | Udemy `, year: "2020" },
        { text: `Business Model Innovation: Differentiate & Grow your company | Udemy `, year: "2020" },
        { text: `Scrum Certification Prep+Scrum Master+Agile Scrum Training | Udemy `, year: "2020" },
        { text: `Top 100 HR Tech Minds | HR Tech - The Future of World at Work `, year: "2018" },
        { text: `HR Excellence award | 7th elets Higher education & Human Resource Conclave, Pune `, year: "2018" },
    ]

    return (
        <div>
            <Helmet>
                <title>Keynote Speaker | Personal & Professional Growth</title>
                <meta name="description" content="Discover motivating, insightful keynote talks by Satish Rajarathnam. Transform your personal and professional life with his powerful messages. Explore his talks today" />
            </Helmet>
            <KeyBanner />
            <AboutNotes title={'KEYNOTE SPEAKER'} content={`Join Satish Rajarathnam, a celebrated TEDx speaker, on an enlightening journey as he mesmerizes audiences with his impactful insights and motivational expertise. He excites and inspires with his vibrant talks, skillfully fusing storytelling and practical tactics. He champions personal and organizational growth, steering through change towards significant achievements. Drawing from his varied experiences in HR, academia, and the arts. He provides also innovative advices for today's business and self-improvement challenges. Be prepared for a transformative experience as Satish's powerful messages and presence insites changes and kindle new potentials.`} />
            <ScheduleMeet />
            <Awards data={data} />
        </div>
    )
}

export default KeyNoteSpeaker