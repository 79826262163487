import React from 'react';
import { useNavigate } from 'react-router-dom';
const TextEffect = () => {
    const push = useNavigate()

    const handleLinkClick = (url) => {
        push(url)
    };

    return (
        <div className='text-effect-wrapper'>
            <div class="rounded-text ">
                <svg viewBox="0 0 200 200">
                    <defs>
                        <path id="textPath" d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0" transform="translate(100,100)" fill="none" stroke-width="0"></path>
                    </defs>
                    <g font-size="13.1px">
                        <text text-anchor="start">
                            <textPath xlinkHref="#textPath" startOffset="0%">
                                <tspan onClick={() => handleLinkClick('/mentalist')} style={{ cursor: 'pointer', fill: '#3f58da' }}>MENTALIST</tspan>
                                {[...Array(20)].map(i => (<tspan key={i}>&nbsp;</tspan>))}
                                <tspan onClick={() => handleLinkClick('/academician')} style={{ cursor: 'pointer', fill: '#3f58da' }}>ACADEMICIAN</tspan>
                                {[...Array(25)].map(i => (<tspan key={i}>&nbsp;</tspan>))}
                                <tspan onClick={() => handleLinkClick('/keynote-speaker')} style={{ cursor: 'pointer', fill: '#3f58da' }}>KEYNOTE SPEAKER</tspan>
                            </textPath>
                        </text>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default TextEffect

