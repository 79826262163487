import { Container, Flex, Title } from '@mantine/core'

const ScheduleMeet = () => {
    return (
        <div className="bg-primary">
            <Container c={'white'} size={'xl'} py={'xl'}>
                <Flex data-aos="fade-up" align='center' gap={'md'} direction={{ md: 'row', base: 'column' }} justify={{ md: 'space-between', base: 'center' }}>
                    <div>
                        <Title ta={{ md: 'left', base: 'center' }}>Let’s talk</Title>
                    </div>
                    <a href='#contact' className='primaryBtn'>Schedule a meet</a>
                </Flex>
            </Container>
        </div>
    )
}

export default ScheduleMeet