import { Box, Button } from '@mantine/core';
import axios from 'axios';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import SectionTitle from '../SectionTitle';
import styles from './PhotoGallery.module.css';


function PhotoGallery() {
    const [loader, setLoader] = useState("");

    const { dataList
    } = useContext(AppContext)
    const [currentCategory, setCurrentCategory] = useState('all');
    const [filteredImaged, setImages] = useState([]);
    const categories = dataList?.dejavu_gallery_categories?.data ?? [];

    const handleTabClick = (categoryId) => {
        setLoader(categoryId)
        setCurrentCategory(categoryId)
        const filters = {}
        if (categoryId !== 'all') {
            filters['id'] = 'category_id';
            filters['value'] = categoryId;
        }
        axios.post(`https://ej-projects.vercel.app/api/web`, {
            moduleName: "dejavu_galleries",
            limit: 50,
            filters: [filters]
        }).then(res => {
            setImages(res.data.data)
        }).finally(() => {
            setLoader("")
        })
    };

    const images = useMemo(() => {
        if (filteredImaged.length) {
            return filteredImaged
        } else {
            return dataList?.dejavu_galleries?.data ?? []
        }
    }, [dataList, filteredImaged])

    return (
        <Box pt={'xl'} id='gallery'>
            <SectionTitle>PORTFOLIO & ACCOMPLISHMENTS</SectionTitle>
            <center data-aos="fade-up">
                <div className={styles.btnGroup}>
                    <Button
                        size='md'
                        onClick={() => handleTabClick('all')}
                        loading={loader === 'all'}
                        disabled={loader === 'all'}
                        className={currentCategory === 'all' ? `${styles.btn} ${styles.active}` : styles.btn}
                    >
                        All
                    </Button>
                    {
                        categories.length ?
                            <>
                                {
                                    categories?.map(category => (
                                        <Button
                                            size='md'
                                            disabled={loader === category._id}
                                            loading={loader === category._id}
                                            key={category._id}
                                            onClick={() => handleTabClick(category._id)}
                                            className={currentCategory === category._id ? `${styles.btn} ${styles.active}` : styles.btn}
                                        >
                                            {category.name}
                                        </Button>
                                    ))}
                            </>
                            : null
                    }
                </div>
            </center>
            <Box px={'lg'} pb={'xl'} data-aos="fade-down">
                <div className={styles.imageBox}>
                    {
                        images.length ?
                            <>
                                {images.map((image, i) => <img key={i} alt={image.category_id + " | Satish Rajaratham"} data-fancybox="gallery" className={styles.image} src={image.image.url} />)}
                            </>
                            : null
                    }

                </div>
            </Box>
        </Box>
    );
}

export default PhotoGallery;
