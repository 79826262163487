import { Button, Container, Flex, Image, Text, Title } from '@mantine/core';
import { Fade } from "react-awesome-reveal";
import { HashLink } from 'react-router-hash-link';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import author02 from '../../assets/images/author-02.png';
import author03 from '../../assets/images/author-03.png';
import author04 from '../../assets/images/author-04.png';
import Effects from '../Effects';
import Features from '../Features';
import TextEffect from '../TextEffect';
import classes from './Banners.module.css';

export default function Banners() {
    const banners = [
        {
            title: 'TEDx speaker, HR Keynote Speaker,',
            subTitle: '& Life Coach',
            content: `Satish Rajarathnam, a captivating TEDx speaker & keynote 
            presenter, combines HR leadership with spellbinding 
            storytelling & transformative insights to deliver an 
            engaging & impactful experience for his audience.`,
            image: author02,
        },
        {
            title: 'Illusionist, Magician &',
            subTitle: 'mind reader',
            content: `Indulge in an unforgettable combination of HR leadership,
            mesmerizing mentalism, & magic with Satish Rajarathnam
            by preparing to be enchanted by his captivating performance
            & impressed by his professional expertise.`,
            image: author03,
        },
        {
            title: `Adjunct Faculty, Corporate Advisory Board Member &`,
            subTitle: 'Patent Publisher',
            content: `Satish Rajarathnam is a highly regarded academician, 
            industry advisor, researcher, & patent author. He is a 
            trailblazer in the field of innovation, working at the 
            crossroads of academia & industry.`,
            image: author04
        }
    ]
    return (
        <>
            <div className={classes.hero}>
                <Effects />
                <Swiper
                    speed={3000}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    pagination={false}
                    modules={[Autoplay]}>
                    {
                        banners.map((item) => (
                            <SwiperSlide>
                                <div className="banner-box">
                                    <div className="banner-content animate__animated animate__fadeInDown">
                                        <Container p={0} pos={'relative'}>
                                            <Fade direction='left' duration={2000}>
                                                <Title className={classes.title}>
                                                    {item.title} <span className={classes.titleSpan}> <br /> {item.subTitle}</span>
                                                </Title>
                                            </Fade>
                                            <Fade direction='left' duration={2000} delay={100}>
                                                <Text className={classes.description} size={{ lg: 'xl', base: 'sm' }} mt={{ lg: 'lg', base: 'sm' }} >{item.content}</Text>
                                            </Fade>
                                            <Fade direction='left' duration={2000} delay={200}>
                                                <Flex gap='md' my='md'>
                                                    <Button variant='filled' color='primary' component={HashLink} to='#contact'>Say Hello</Button>
                                                    <Button variant='outline' color='primary' component={HashLink} to='/#gallery'>Gallery</Button>
                                                </Flex>
                                            </Fade>
                                            <TextEffect />
                                        </Container>
                                    </div>
                                    <div className="banner-image-box">

                                        {/* <div className="banner-effect-box">
                                            <img src={require('../../assets/images/banner-effect.png')} className='banner-effect' alt="n" />
                                            <img src={require('../../assets/images/banner-effect-text.png')} className='banner-effect-text' alt="n" />
                                        </div> */}
                                        <Fade direction='up' duration={2000}>
                                            <Image alt='banner' src={item.image} className='banner-image' />
                                        </Fade>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

            </div >
            <Features />
            <div className={classes.brandLogos} >
                <a target='_blank' href="https://hr.economictimes.indiatimes.com/news/industry/satish-rajarathnam-joins-rcg-global-services-as-head-of-people-administration/106567841"><img alt='icon' src={require('../../assets/icons/et.png')} /></a>
                <a target='_blank' href="https://www.forbesindia.com/top30talentleaders/season_1/"><img alt='icon' src={require('../../assets/icons/fm.png')} /></a>
                <a target='_blank' href="https://www.ted.com/tedx/events/31305"><img alt='icon' src={require('../../assets/icons/ts.png')} /></a>
            </div>
        </>
    );
}