import { Box } from '@mantine/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import Section from '../components/Section'
import SectionTitle from '../components/SectionTitle'

const Contact = () => {
    return (
        <Section>
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <Box my={'xl'} py={'xl'} >
                <SectionTitle>Contact Us</SectionTitle>
            </Box>
        </Section>
    )
}

export default Contact