import { generateColors } from '@mantine/colors-generator';
import { createTheme } from '@mantine/core';

export const theme = createTheme({
  primaryColor: 'primary',
  colors: {
    primary: generateColors('#2A4EC9'),
    primaryDark: ['#011142', '#011142', '#011142', '#011142', '#011142', '#011142', '#011142', '#011142', '#011142', '#011142'],
  },
  fontFamily: 'Open Sans',
  headings: { fontFamily: 'AlienAndroid' },
});
