import { Helmet } from 'react-helmet'
import Banners from '../components/Banners'
import Clients from '../components/Clients'
import PhotoGallery from '../components/PhotoGallery'
import Testimonials from '../components/Testimonials'
import VideoSliders from '../components/VideosSliders'

function Home() {
    return (
        <div>
            <Helmet>
                <title>TEDx & HR Keynote Speaker | Life Coach Expert | Satish Rajaratham </title>
                <meta name="description" content="Transform with a TEDx speaker and HR expert. Enhance personal excellence and professional growth through inspiring, and motivating coaching sessions." />
            </Helmet>
            <Banners />
            <VideoSliders />
            <PhotoGallery />
            <Clients />
            <Testimonials />
        </div>
    )
}

export default Home;