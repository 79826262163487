import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { requestsData } from '../configs/requestsData';
import { AppContext } from '../context/AppContext';

const ApiProvider = ({ children }) => {
    const [dataList, setDataList] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchDataSequentially = async () => {
            setIsLoading(true);
            try {
                for (const { method, url, payload, limit } of requestsData) {
                    const response = await axios.request({ method, url, data: payload, limit });
                    setDataList(prevData => ({
                        ...prevData,
                        [payload.moduleName]: response.data
                    }));
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDataSequentially();
    }, []);
    return (
        <AppContext.Provider value={{ dataList, error, isLoading }}>
            {children}
        </AppContext.Provider>
    )
}

export default ApiProvider