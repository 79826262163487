import { Helmet } from 'react-helmet'
import AboutNotes from '../components/AboutNotes'
import Awards from '../components/Awards'
import MentalistBanner from '../components/MentalistBanner'
import ScheduleMeet from '../components/ScheduleMeet'

const Mentalist = () => {
    const data = [
        {
            "text": "Journeys to the Board Room | Vijay Patil School of Management",
            "year": 2024
        },
        {
            "text": "Top 30 Talent Leaders | presented by Forbe’s powered by indeed",
            "year": 2023
        },
        {
            "text": "Influential HR Leaders | HRAI - HR association of India",
            "year": 2023
        },
        {
            "text": "Top 20 workplace Reinvention champions | L&D vision & Innovation summit",
            "year": 2022
        },
        {
            "text": "Asia’s 100 power leaders in Human Resources | White page International",
            "year": 2022
        },
        {
            "text": "Global Achievers Award | Indian Achievers form",
            "year": 2022
        },
        {
            "text": "Leader of the year | HR Tech Summit",
            "year": 2022
        },
        {
            "text": "The voice of the Employee | Josh Bersin Academy",
            "year": 2021
        },
        {
            "text": "Indian Achievers Award | Indian Achievers form",
            "year": 2021
        },
        {
            "text": "HR Excellence award | 7th elets Higher education & Human Resource Conclave, Pune",
            "year": 2018
        },
        {
            "text": "Top 100 HR Tech Minds | HR Tech - The Future of World at Work",
            "year": 2018
        },
        {
            "text": "General Management Conclave | INACON",
            "year": 2018
        }
    ]

    return (
        <div>
            <Helmet>
                <title>Satish Rajarathnam | HR Leadership, Mentalism, Magic</title>
                <meta name="description" content="Experience HR leadership fused with mesmerizing mentalism and magic by Satish Rajarathnam. Enjoy a unique showcase of expertise and entertainment in one." />
            </Helmet>
            <MentalistBanner />
            <AboutNotes title='MENTALIST' content={`Join Satish Rajarathnam on a captivating journey as he takes on the roles of a distinguished HR leader, mentalist, and magician. With over 1,300 performances under his belt, Satish seamlessly blends the art of illusion with his deep understanding of human psychology. As a mentalist, he leaves his audiences absolutely mesmerized with mind-bending feats, while his magical abilities leave spectators spellbound. Come and witness the magic and mystery of Satish's diverse repertoire, where reality and illusion blent to an unforgettable spectacle of wonder.`} />
            <ScheduleMeet />
            <Awards data={data} />
        </div>
    )
}

export default Mentalist