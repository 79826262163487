import { Box, Container, Flex, Grid, GridCol, List, Text } from '@mantine/core';
import React from 'react';
import SectionTitle from '../SectionTitle';

const Awards = ({ data }) => {
    return (
        <>
            <Container py={'xl'} size={'lg'}>
                <SectionTitle>AWARDS & ACHIEVEMENTS</SectionTitle>
                <List
                    mt={'xl'}
                    spacing="xs"
                    size="lg"
                    icon={<img alt='AWARDS & ACHIEVEMENTS' src={require('../../assets/images/wreath.png')} width={30} />}
                    data-aos="fade-up"
                    w={{ lg: '70%' }}
                    mx={'auto'}
                >
                    {
                        data.map((item, i) => (
                            <List.Item key={i} lh={'xl'}>
                                <Text className='bb' component='span'>{item.text} <Text component='span' c={'primary'}>{item.year}</Text></Text>
                            </List.Item>
                        ))
                    }
                </List>
            </Container>
            {
                window.location.pathname === '/academician' ?
                    <Box mt="xl" bg={'#eee'}>
                        <Container py={'xl'}>
                            <Grid align='center'>
                                <GridCol span={{ lg: 8 }}>
                                    <Flex justify={{ lg: 'space-between', base: 'center' }} wrap={'wrap'}>
                                        <Box p='lg'>
                                            <a target='_blank' href="https://www.flipkart.com/methodology-educational-research/p/itm1e97a1e910af8">
                                                <img alt='book' src={require('../../assets/images/book (1).png')} width='100%' />
                                            </a>
                                        </Box>
                                        <Box p='lg'>
                                            <a target='_blank' href="https://www.amazon.in/ORGANIZATIONAL-BEHAVIOUR-HUMAN-RESOURCE-MANAGEMENT/dp/B0BHTQFCMD ">
                                                <img alt='book' src={require('../../assets/images/book (2).png')} width='100%' />
                                            </a>
                                        </Box>
                                        <Box p='lg'>
                                            <a target='_blank' href="https://www.amazon.in/INTRODUCTION-APPLICATIONS-Dr-Satish-Rajarathnam/dp/8196063474">
                                                <img alt='book' src={require('../../assets/images/book (3).png')} width='100%' />
                                            </a>
                                        </Box>
                                    </Flex>
                                </GridCol>
                                <GridCol span={{ lg: 4 }} ta={{ lg: 'right', base: 'center' }}>
                                    <Text my="lg" pb="md" size='lg'>Discover captivating the reads from <b>Satish Rajaratham</b> collection</Text>
                                    <Flex justify={{ lg: 'end', base: 'center' }} align={'center'}>
                                        <img alt='amazon' src={require('../../assets/images/amazon.png')} style={{ marginTop: '5px' }} width='100px' />
                                        <Box mx="md">|</Box>
                                        <img alt='flipkart' src={require('../../assets/images/flipkart.png')} width='100px' />
                                    </Flex>
                                    <Box><small>Available on Amazon & Flipkart</small></Box>
                                </GridCol>
                            </Grid>
                        </Container>
                    </Box>
                    : null
            }
        </>
    )
}

export default Awards