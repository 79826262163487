import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import 'animate.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'swiper/css/effect-fade';
import Layout from './Layout';
import Academician from "./pages/Academician";
import Contact from "./pages/Contact";
import Home from './pages/Home';
import KeyNoteSpeaker from './pages/KeyNoteSpeaker';
import Mentalist from "./pages/Mentalist";
import { theme } from './theme';

Fancybox.bind('[data-fancybox="gallery"]');

Aos.init();
function App() {
  return (
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route Component={Layout}>
            <Route index Component={Home} />
            <Route path='/keynote-speaker' Component={KeyNoteSpeaker} />
            <Route path='/mentalist' Component={Mentalist} />
            <Route path='/academician' Component={Academician} />
            <Route path='/contact' Component={Contact} />
          </Route>
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  )
}

export default App