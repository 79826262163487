import { Box, Button, Container, Flex, Loader, Text } from '@mantine/core';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import ReactPlayer from 'react-player';
import 'swiper/css';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppContext } from '../../context/AppContext';
import SectionTitle from '../SectionTitle';
import './videoSliders.scss';

const VideoSliders = () => {
  const { dataList } = useContext(AppContext)
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);
  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  const videos = useMemo(() => {
    return dataList?.dejavu_videos?.data || []
  }, [dataList])

  return (
    <Box className="videoSliders" py="xl">
      <Container>
        <SectionTitle>Watch our videos</SectionTitle> <br />
        <Box mb="lg">
          <div className="col">
            <Text size={"xl"} mb={0} lh={0} fw={500}>Mind Magic: Unveiling Mysteries</Text>
            <p className="videoSliders-details m-0">
              Discover astonishing mentalist tricks, bending reality with every mesmerizing moment. Be prepare to be amazed!
            </p>
          </div>
        </Box>
      </Container>
      {
        videos.length ?
          <>
            <Swiper
              autoplay={{
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
              }}
              ref={swiperRef}
              pagination={false}
              slidesPerView={'auto'}
              spaceBetween={25}
              freeMode={true}
              centeredSlides={true}
              breakpoints={{
                "1200": {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
              }}
              effect={'coverflow'}
              grabCursor={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 20,
                modifier: 1,
                slideShadows: true,
              }}
              slideToClickedSlide={true}
              modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
              onActiveIndexChange={(element) => setActiveIndex(element.activeIndex)}
              autoplayDisableOnInteraction={true}
            >
              {videos.map((item, index) => (
                <SwiperSlide className="videoSliders" key={index}
                  autoPlay={true}
                  watchSlidesProgress={true}>
                  <div className="bg-dark-500">
                    <ReactPlayer
                      loop
                      key={item._id}
                      url={item.embed_url}
                      controls={true}
                      muted={true}
                      playing={activeIndex === index}
                      width="100%" height={window.innerWidth > 992 ? 520 : 320}
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            modestbranding: 1,
                            rel: 0,
                            iv_load_policy: 3,
                          }

                        }
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Container mt="lg">
              <Box mb="lg" pb="lg">
                <Button radius={'xl'} variant='outline' color='primary' mr="md" onClick={() => prevSlide()}><FaAngleLeft /></Button>
                <Button radius={'xl'} variant='outline' color='primary' onClick={() => nextSlide()}><FaAngleRight /></Button>
              </Box>
            </Container>
          </>
          : <Flex justify={'center'} align={'center'} style={{ minHeight: '70vh' }}>
            <Loader />
          </Flex>
      }
    </Box>
  );
};

export default VideoSliders;
