import { Burger, Container, NavLink as CustomNavLink, Drawer, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink } from 'react-router-dom';
import classes from './header.module.css';

const links = [
    { link: '/', label: 'HOME' },
    { link: '/keynote-speaker', label: 'Keynote speaker' },
    { link: '/mentalist', label: 'Mentalist' },
    { link: '/academician', label: 'Academician' },
    { link: '/contact', label: 'CONTACT' },
];

function Header() {
    const [opened, { toggle }] = useDisclosure(false);

    const items = links.map((link) => (
        <NavLink key={link.label} to={link.link} className={classes.link}>{link.label}</NavLink>
    ));

    return (
        <div style={{ marginBottom: '56px' }}>
            <header className={classes.header + " animate__animated  animate__fadeInDown"}>
                <Container size="md" className={classes.inner}>
                    <Group gap={'xl'} visibleFrom="xs" mx={'auto'} justify='center'>
                        {items}
                    </Group>
                    <Burger opened={opened} onClick={toggle} hiddenFrom="xs" color='white' size="sm" />
                </Container>
                <Drawer opened={opened} onClose={toggle} title="" >
                    {links.map(link => (<CustomNavLink onClick={() => toggle()} key={link.label} className={classes.navLink} component={NavLink} tt={'uppercase'} to={link.link} variant="filled" label={link.label} />))}
                </Drawer>
            </header>
        </div>
    );
}

export default Header
