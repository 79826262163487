import { Container, Grid, Image, Paper, Text, rem } from '@mantine/core';
import React, { useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppContext } from '../../context/AppContext';
import Section from '../Section';
import SectionTitle from '../SectionTitle';


function Testimonials() {
    const { dataList } = useContext(AppContext)

    return (
        <Section>
            {
                window.innerWidth < 768 ?
                    <SectionTitle align="center"> What <br />they say</SectionTitle>
                    : null
            }
            <Container size={'xl'}>
                <Grid gutter="md" align='center' >
                    <Grid.Col span={{ lg: 9 }} >
                        <Fade direction='up'>
                            {
                                dataList?.dejavu_testimonials?.data?.length ?
                                    <Swiper
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        autoplay={{ delay: 3000 }}
                                        loop={true}
                                        navigation={true}
                                        breakpoints={{
                                            992: {
                                                slidesPerView: 3
                                            }
                                        }}
                                        modules={[Autoplay]}
                                    >
                                        {
                                            dataList.dejavu_testimonials.data.map((item, i) => (
                                                <SwiperSlide key={i} >
                                                    <div>
                                                        <Paper ta="center" className='user-slide' mih={210}>
                                                            <Text size="lg" weight={500}>{item.name}</Text>
                                                            <Text>{item.review}</Text>
                                                        </Paper>
                                                        <Image alt='testimonial' src={item.image.url} w={rem(68)} mx={'auto'} className='user-image' />
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    : null
                            }
                        </Fade>
                    </Grid.Col>
                    {
                        window.innerWidth > 768 ?
                            <Grid.Col span={{ lg: 3 }}>
                                <SectionTitle align="right"> What <br />they say</SectionTitle>
                            </Grid.Col>
                            : null
                    }
                </Grid>
            </Container>
        </Section>
    );
}

export default Testimonials;
