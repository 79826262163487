import React from 'react'
import { Fade } from 'react-awesome-reveal'
import styles from './SectionTitle.module.css'

function SectionTitle({ children, align = 'center' }) {
  const isRight = window.innerWidth > 992 ? styles.right : 'center'
  const isCenter = align === 'center' ? styles.center : isRight
  return (
    <Fade direction='up'>
      <h2 className={`${styles.title} ${isCenter}`}>{children}</h2>
    </Fade>
  )
}

export default SectionTitle