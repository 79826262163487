import { Box, Container, Flex } from '@mantine/core'
import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { AppContext } from '../../context/AppContext'
import Section from '../Section'
import SectionTitle from '../SectionTitle'

function Clients() {
    const { dataList } = useContext(AppContext)
    return (
        <Box bg={'#eeeeee'}>
            <Section>
                <SectionTitle>Key partners</SectionTitle>
                <Fade direction='up'>
                    <Container size={'xl'} py={'xl'}>
                        {
                            dataList?.dejavu_clients?.data ?
                                <Flex wrap={'wrap'} justify={'center'} align={'center'} gap={{ base: 20 }} mt={'lg'}>
                                    {dataList?.dejavu_clients?.data.map(item => (
                                        <div className='client-border'>
                                            <img alt='client' src={item.image.url} width='150px' className='hover-color ' />
                                        </div>
                                    ))}
                                </Flex>
                                : null
                        }
                    </Container>
                </Fade>
            </Section>
        </Box>
    )
}

export default Clients